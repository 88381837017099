import { BrowserRouter as Router,Routes, Route, Link } from "react-router-dom";
import styled from 'styled-components';
import Home from "./Home";
import Test123 from "./Test123";
// import AlbumsList from "./features/albums/AlbumsList";
// import AlbumInfo from "./features/albums/AlbumInfo";
// import { getAlbumsData } from "./features/albums/albumsSlice";
import Navigation from "./Navigation";
import CampsPage from "./CampsPage";
import ComingSoon from "./ComingSoon";
import ProRun from "./ProRun";
import Enter from "./Enter";
import FinalEnter from "./FinalEnter";
import Shoes from "./Shoes";
import Sport from "./Sport";
import Rayhome from "./Rayhome";
import RayList from "./RayList";

const StyledApp = styled.div`
background-color: #070707;
display: flex;
flex-direction: column;
  .flexy {
    display: flex;
    justify-content: center;
    background: #070707;
    margin-top: 12vh;
  }
@media screen and (max-width:720px){  
  .flexy {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
  }
  
  .use-mobile{
    display: none;
  }
  @media screen and (min-width:720px){  
    .use-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0;
      position: fixed;
      height: 120vh;
      width: 100vw;
      background-color: #111;
      color: #eee;
      justify-content: center;
      align-items: center;
      z-index: 999999999999999999999999999;

    }
    }



`;


const App = () => {
  // const albumsData = getAlbumsData();
  window.scrollTo({
    top: 0,
    left: 100,
    behavior: 'smooth'
  })
  return (
    <Router>

    <StyledApp>
      {/* <div className="">
        <h2 style={{fontSize:'2rem'}}>Desktop Site coming soon</h2>
        <p>please use mobile version</p>
      </div> */}
              <div style={{background:'#2a2a2a',position:'absolute',top:'0',zIndex:'999999999999999',paddingTop:'0.12em',paddingBottom:'0.32em',boxSizing:'border-box',minWidth:'100vw'}} className="px-4 text-white" >
  <p style={{padding:'0.32em'}} className="text-center text-sm font-medium">
  {/* Denard Bros Pro Runs are BACK & BETTER than EVER.  */}
    
  </p>
</div>
   {/* <Navigation /> */}
      <Routes>
        <Route exact path="/" element={<><Rayhome /><Shoes /></>} />
        <Route exact path="/leads" element={<><RayList /></>} />
        {/* <Route exact path="/camps" element={<CampsPage />} />
        <Route exact path="/prorun" element={<ProRun />} />
        <Route exact path="/enter" element={<Enter />} />
        <Route exact path="/youenter" element={<FinalEnter />} /> */}
        {/* <Route
          path="/albums"
          element={<AlbumsList albumsData={albumsData} />}
        />
        <Route
          path="/albums/:albumId"
          element={<AlbumInfo albumsData={albumsData} />}
        /> */}
      </Routes>
    </StyledApp>
    </Router>
  );
};

export default App;
